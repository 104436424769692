<template>
    <div>
        <Row class="search-bar">
            <Col span="12">
                <Dropdown class="dropdown" trigger="click" placement="bottom-start" @on-visible-change="handleTreeDropdown">
                    <Icon v-if="nodeList.length" class="close" type="ios-close-circle" @click="clearNodeList" />
                    <Button type="text">
                        组织架构
                        <div v-if="nodeList.length" class="tree line-overflow">
                            <span>:</span>
                            <span v-for="(item,index) in nodeList" :key="item.department_id">{{ item.title }}
                                <span v-if="index !== nodeList.length - 1">,</span>
                            </span>
                        </div>
                        <Icon type="ios-arrow-down"></Icon>
                    </Button>
                    <DropdownMenu slot="list">
                        <Tree :data="data" show-checkbox @on-check-change="handleTree" @on-select-change="handleSelectChange"></Tree>
                    </DropdownMenu>
                </Dropdown>
                <Dropdown class="status dropdown" trigger="click" placement="bottom-start" >
                    <Icon v-if="status" class="close" type="ios-close-circle" @click="clearStatusList" />
                    <Button type="text">
                        状态<span v-if="status">:{{ status.text._ }}</span>
                        <Icon type="ios-arrow-down"></Icon>
                    </Button>
                    <DropdownMenu slot="list">
                        <DropdownItem v-for="(item, index) in statusList" :key="index" @click.native="handleStatusList(item)">{{ item.text._ }}</DropdownItem>
                    </DropdownMenu>
                </Dropdown>
            </Col>
            <Col span="12">
                <Input v-model="query" class="float-right" clearable placeholder="搜索屏幕名称" style="width: auto" @keyup.native.enter="searchNew" @on-clear="searchNew" >
                    <Icon type="ios-search" slot="suffix" @click="searchNew" />
                </Input>
            </Col>
        </Row>
        <div class="checkbox-group-wrap">
            <CheckboxGroup v-show="list.length > 0" class="devices-wrap clearfix" v-model="checkAllGroup" @on-change="checkAllGroupChange">
                <div class="checkbox-wrap" v-for="item in list" :key="item.rowid" >
                    <div class="img-wrap-block">
                        <div :class="['img-wrap', item.dir==='vertical'?'img-wrap-vertical':'']">
                            <img class="image-center" :src="`/cym/${item.cover_uri}`">
                            <div class="device-status">
                                <Badge :status="item.state === 'online' ? 'success' : 'error'" />
                                <span class="text">{{ item.state_text._ }}</span>
                            </div>
                        </div>
                    </div>
                    <div v-if="checkAllGroup.length === 0" class="mask"></div>
                    <div class="device-info">
                        <div class="device-name name-wrap">
                            <div class="name line-overflow">
                                {{ item.name }}
                            </div>
                        </div>
                        <div class="device-id line-overflow">ID:&nbsp;{{ item.serial }}</div>
                        <div v-if="item.department_name" class="device-site line-overflow">{{ item.department_name }}</div>
                    </div>
                    <Checkbox class="checkbox" :label="item.rowid"><span>&nbsp;</span></Checkbox>
                </div>
            </CheckboxGroup>
            <Page
                v-show="list.length > 0"
                class="page"
                :total="total"
                :current="current"
                :page-size="pageSize"
                show-elevator
                @on-change="nextPage" />
            <noData v-show="list.length===0" text="暂无价签" />
            <Spin fix size="large" v-if="loading"></Spin>
        </div>
        <div class="modal-footer">
            <Checkbox
                v-if="list.length !== 0"
                style="float: left;"
                :indeterminate="indeterminate"
                :value="checkAll"
                @click.prevent.native="handleCheckAll">
                <span v-if="checkAllGroup.length === 0">全选</span>
                <span v-else>已选中{{ checkAllGroup.length }}个屏幕</span>
            </Checkbox>
            <Button class="back margin-right" type="default" @click="handleBack">上一步</Button>
            <Button :disabled="checkAllGroup.length?false:true" :loading="submitting" type="primary"  @click="handleSubmit">立即投放</Button>
        </div>
    </div>  
</template>
<script>
    import { checkbox, dropdownTree } from '@/mixins/index'
    import noData from 'views/components/noData'

    export default {
        name: "",
        mixins: [checkbox, dropdownTree],
        components: {
            noData
        },
        props: {
            handleCount: Function,
            confirm: Function,
            target_size: {
                default: ''
            }
        },
        data () {
            return {
                total: 0,
                list: [],
                current: 1,
                pageSize: 12,
                nodeList: [],
                checkAllGroup: [],
                query: '',
                data: [],
                loading: false,
                checkAll: false,
                indeterminate: false,
                submitting: false,
                statusList: [],
                status: null,
            }
        },
        computed: {
            tree_data() {
                return [this.$store.state.user_tree]
            },
        },
        watch: {
            checkAllGroup(val) {
                this.handleCount(val.length)
            }
        },
        methods: {
            searchNew() {
                if (this.loading) return
                this.current = 1
                this.getData()
            },
            nextPage(e) {
                this.current = e
                this.getData()
            },
            getData() {
                let params = {
                    query: this.query,
                    start: (this.current - 1) * this.pageSize,
                    count: this.pageSize,
                    repeater_type: 'all',
                    target_size: this.target_size
                }
                if ( this.nodeList.length) {
                    let department_id_list = []
                    this.nodeList.map((item) => {
                        department_id_list.push(item.department_id)
                    })
                    params.department_id_list = JSON.stringify(department_id_list)
                } else {
                    this.treeList = []
                    this.getTreeList(this.tree_data, 'department_id')
                    params.department_id_list = JSON.stringify(this.treeList)
                }
                if (this.status) {
                    params.state = this.status.status
                }
                this.loading = true
                this.$api.get('terminals/search', { params }).then(({ data, total_count, page }) => {
                    if (this.current === page) {
                        let isAll = true
                        data.map((item) => {
                            if (this.checkAllGroup.indexOf(item.rowid) === -1 || this.checkAllGroup.length === 0) isAll = false
                        })
                        if (isAll) {
                            this.indeterminate = false;
                            this.checkAll = true;
                        }
                        this.list = data
                        this.total = total_count
                        this.loading = false
                    }
                }).catch(() => {
                    this.loading = false
                })
            },
            getStatusList() {
                this.$api.get('terminals/status/list').then(({ data }) => {
                    this.statusList = data
                })
            },
            handleStatusList(item) {
                this.current = 1
                this.status = item
                this.getData()
            },
            clearStatusList() {
                this.current = 1
                this.status = null
                this.getData()
            },
            clear() {
                this.list = 0
                this.current = 1
                this.checkAllGroup = []
                this.query = ''
                this.total = 0
                this.nodeList = []
                this.checkAll = false
                this.indeterminate = false
                this.statusList = []
                this.status = null
            },
            handleBack() {
                this.$emit('handleTab', 'tmpl')
            },
            done() {
                this.submitting = false
            },
            handleSubmit() {
                this.submitting = true
                this.confirm(this.done)
            }
        },
        mounted() {
            this.data  = this.handleRecursive(this.tree_data)
            this.getStatusList()
        }
    }
</script>
<style scoped lang="less">
@import "~less/color";
.dropdown {
    position: relative;
    vertical-align: bottom;
    .close {
        display: none;
    }
    .label-mag {
        cursor: pointer;
        padding: 7px 0 7px 4px;
        font-size: 14px;
    }
    &:hover {
        .close {
            display: block;
            position: absolute;
            right: 0px;
            top: 0px;
        }
    }
    .tree {
        display: inline-block;
        max-width: 100px;
        vertical-align: bottom;
    }
}
.checkbox-group-wrap {
    overflow-x: hidden;
    position: relative;
    .devices-wrap {
        width: 1176px;
        .checkbox-wrap {
            position: relative;
            display: inline-block;
            margin: 0 8px 31px 0;
            padding-left: 0.7%;
            border: 1px solid #d3d3d3;
            width: calc(25% - 8px);
            padding-top: 13%;
            background: #fff;
            &:hover {
                .mask {
                    opacity: 1;
                }
            }
            .mask {
                opacity: 0;
                position: absolute;
                top: 0;
                left: 0;
                width: 100%;
                height: 100%;
                background: rgba(0,0,0,.08);
                transition: opacity .3s ease;
            }
            .checkbox {
                position: absolute;
                top: 0;
                left: 0;
                margin-right: 0;
                padding: 5px;
            }
            .checked {
                display: inline-block;
                width: 100%;
                height: 100%;

            }
            .img-wrap-block {
                position: absolute;
                left: 0;
                top: 0;
                width: 38%;
                height: 100%;
                background: #f6f7f9;
                .img-wrap {
                    position: absolute;
                    top: 31%;
                    bottom: 31%;
                    left: 10%;
                    right: 10%;
                    .device-status {
                        position: absolute;
                        left: 5%;
                        bottom: 12%;
                        width: 40px;
                        height: 16px;
                        padding-left: 5px;
                        border-radius: 4px;
                        background: #000;
                        color: #fff;
                        line-height: 16px;
                        .ivu-badge-status {
                            width: 10px;
                            height: 100%;
                        }
                        .text {
                            position: absolute;
                            left: 12px;
                            top: 50%;
                            transform: translateY(-50%) scale(0.8);
                            font-size: 10px;
                            display: block;
                        }
                    }
                }
                .img-wrap-vertical {
                    position: absolute;
                    top: 15%;
                    bottom: 19%;
                    left: 25%;
                    right: 25%;
                    .category {
                        left: 0%;
                        top: 7%;
                    }
                    .device-status {
                        position: absolute;
                        left: 8%;
                        bottom: 7%;
                    }
                }
            }
            .device-info {
                position: absolute;
                top: 50%;
                left: 45%;
                transform: translateY(-50%);
                width: 53%;
                min-width: 100px;
                .device-name {
                    position: relative;
                    height: 33px;
                    font-weight: 700;
                    font-size: 10px;
                    width: calc(100% + 16px);
                    .name {
                        display: inline-block;
                        position: relative;
                        max-width: calc(100% - 24px);
                        font-weight: 700;
                        font-size: 10px;
                        line-height: 33px;
                        vertical-align: middle;
                    }
                    .icon {
                        vertical-align: middle;
                    }
                }
                .device-id {
                    font-size: 10px;
                    font-weight: 700;
                }
                .device-site {
                    font-size: 10px;
                }
                .device-plan {
                    display: inline-block;
                    margin-top: 4px;
                    padding: 0 10px;
                    height: 28px;
                    max-width: 100px;
                    background: @primary-color;
                    border-radius: 4px;
                    color: #fff;
                    text-align: center;
                    line-height: 28px;
                    font-size: 12px;
                }
            }
            .checkbox {
                position: absolute;
                left: 0;
                top: 0;
                margin-right: 0;
                padding: 10px 0 0 18px;
                width: 100%;
                height: 100%;
            }
        }
    }
}
.modal-footer {
    text-align: right;
    .cancel {
        margin-right: 12px;
    }
}
</style>