<template>
    <Modal
        class="wrap"
        v-model="modal"
        footer-hide
        title="数据字段管理"
        :width="800"
        :mask-closable="false"
        @on-visible-change="handleVisibleChange">
        <div class="parameters-section">
            <div class="parameters-title">
                <span class="text">自定义字段</span>
                <Button class="float-right" type="primary" @click="handleAddParameter">新增</Button>
            </div>
            <div class="parameters-con">
                <Dropdown class="dropdown line-overflow" v-for="(item, key) in parameters" :key="key" trigger="hover" placement="bottom-start" transfer >
                    <span class="item pointer">
                        <span class="type">{{ item.type_text._ }}</span>
                        {{ item.text._ }}
                        <Icon class="label-more" type="md-more" />
                    </span>
                    <DropdownMenu slot="list">
                        <DropdownItem @click.native="handleEditParameter(item, key)">编辑</DropdownItem>
                        <DropdownItem @click.native="handleRemove(item, key)">删除</DropdownItem>
                    </DropdownMenu>
                </Dropdown>
            </div>
        </div>
        <div class="data-section">
            <div class="data-title">
                <span class="text">已有字段</span>
            </div>
            <div class="data-con">
                <span class="item" v-for="(item,key) in data" :key="key" >
                    <span class="type">{{ item.type_text._ }}</span>
                    {{ item.text._ }}
                </span>
            </div>
        </div>
        <parameter-modal
            :show="parameterModalShow"
            :state="parameterModalState"
            :parameterInfo="parameterInfo"
            :bucket_token="bucket_token"
            @cancel="handleParamModalCancel"
            @confirm="handleParamModalConfirm"
        ></parameter-modal>
    </Modal>
</template>
<script>
    import parameterModal from './parameterModal'

    export default {
        name: "",
        mixins: [],
        components: {
            parameterModal
        },
        props: {
            show: {
                default: false
            },
            bucket_token: {
                default: ''
            }
        },
        data () {
            return {
                modal: false,
                loading: false,
                parameters: {},
                data: {},
                parameterModalShow: false,
                parameterModalState: 'add',
                parameterInfo: {}
            }
        },
        computed: {},
        watch: {
            show(val) {
                this.modal = val
                if (val) {
                    this.getList()
                }
            }
        },
        methods: {
            handleVisibleChange(e) {
                if (!e) {
                    this.cancel()
                }
            },
            cancel() {
                this.$emit('cancel')
            },
            handleRemove(item, key) {
                this.$Modal.confirm({
                    title: '删除提示',
                    content: `确定删除"${item.text._}"?`,
                    okText: '确定删除',
                    cancelText: '取消',
                    closable: true,
                    onOk: () => {
                        let params = {
                            bucket_token: this.bucket_token,
                            field_key: key,
                            data: {
                                text: {
                                    _: item.text._
                                },
                                type: item.type,
                                visible: false
                            }
                        }
                        this.$api.put(`commodities/fields/${key}`, params).then(() => {
                            this.$delete(this.parameters, key)
                            this.getList()
                        }).catch(() => {})

                    }
                })
            },
            handleAddParameter() {
                this.parameterModalShow = true
                this.parameterModalState = 'add'
                this.parameterInfo = {}
            },
            handleEditParameter(item, key) {
                this.parameterModalShow = true
                this.parameterModalState = 'edit'
                this.parameterInfo = item
                this.parameterInfo.field_key = key
            },
            handleParamModalCancel() {
                this.parameterModalShow = false
            },
            handleParamModalConfirm(params) {
                let data = {
                    bucket_token: this.bucket_token,
                    field_key: params.field_key,
                    data: params.data
                }
                this.$api.put(`commodities/fields/${params.field_key}`, data).then(() => {
                    this.$set(this.parameters, params.field_key, params.data)
                    this.getList()
                })
                this.handleParamModalCancel()
            },
            getList() {
                let params = {
                    bucket_token: this.bucket_token
                }
                this.$api.get('commodities/fields', { params }).then(({ data={} }) => {
                    for (let value in data) {
                        if (data[value].column === 'data' && data[value].visible) {
                            this.$set(this.data, value, data[value])
                        }
                        if (data[value].column === 'parameters' && data[value].visible) {
                            this.$set(this.parameters, value, data[value])
                        }
                    }
                })
            },
        },
        mounted() {}
    }
</script>
<style scoped lang="less">
@import "~less/color";

.parameters-section {
    margin-bottom: 20px;
    .parameters-title {
        padding-bottom: 10px;
        border-bottom: 2px solid rgba(175,180,186, 0.2);
        .text {
            font-size: 16px;
            line-height: 32px;
            font-weight: 700;
        }
    }
    .parameters-con {
        .dropdown {
            margin: 10px 10px 0 0;
            .label {
                position: relative;
                background: #efefef;
                .label-more {
                    opacity: 0;
                    transition: all .3 ease-in;
                }
                &:hover {
                    .label-more {
                        opacity: 1;
                        display: inline-block;
                    }
                }
                .type {
                    padding: 0 2px;
                    background: @primary-color;
                    border-radius: 2px;
                    color: #fff;
                    font-size: 10px;
                    line-height: 16px;
                }
            }
            .item {
                position: relative;
                display: inline-block;
                margin: 10px 10px 0 0;
                padding: 0 15px;
                height: 32px;
                line-height: 32px;
                background: #efefef;
                border-radius: 32px;
                .type {
                    padding: 0 2px;
                    background: @primary-color;
                    border-radius: 2px;
                    color: #fff;
                    font-size: 10px;
                    line-height: 16px;
                }
            }
        }
    }
}
.data-section {
    .data-title {
        padding-bottom: 10px;
        border-bottom: 2px solid rgba(175, 180, 186, 0.2);
        .text {
            font-size: 16px;
            line-height: 32px;
            font-weight: 700;
        }
    }
    .data-con {
        .item {
            position: relative;
            display: inline-block;
            margin: 20px 20px 0 0;
            padding: 0 15px;
            height: 32px;
            line-height: 32px;
            background: #efefef;
            border-radius: 32px;
            .type {
                overflow: hidden;
                padding: 0 2px;
                background: @primary-color;
                border-radius: 2px;
                color: #fff;
                font-size: 10px;
                line-height: 16px;
            }
        }
    }
}
</style>