<template>
    <Modal
        class="wrap"
        v-model="modal"
        title="快捷投放"
        footer-hide
        :mask-closable="false"
        width="1200"
        @on-visible-change="handleVisibleChange">
        <div class="tabs">
            <div class="tabPane tabPaneActive">选择模板</div>
            <div :class="['tabPane tabPaneActive', currentTab==='tag'?'tabPaneActive':'']">选择屏幕({{ tagsCount }})</div>
            <tagsComponent
                ref="tagsComponent"
                class="tag-section"
                v-show="currentTab==='tag'"
                :handleCount="handleTagsCount"
                :target_size="target_size"
                @handleTab="handleTab"
                @cancel="cancel"
                :confirm="handleSubmit"/>
            <tmplsComponent
                ref="tmplsComponent"
                v-show="currentTab==='tmpl'"
                class="tmplsComponent"
                :commodities="commodityList.length"
                :is_org="is_org"
                @handleTab="handleTab"
                @handleTargetSize="handleTargetSize"
                @cancel="cancel"/>
        </div>
    </Modal> 
</template>
<script>
    import { dropdownTree } from '@/mixins/index'
    import tagsComponent from './tagsComponent'
    import tmplsComponent from './tmplsComponent'

    export default {
        name: "",
        mixins: [dropdownTree],
        components: {
            tagsComponent,
            tmplsComponent
        },
        props: {
            show: {
                default: false
            },
            commodityList: {
                default: () => {
                    return []
                }
            },
        },
        data () {
            return {
                modal: false,
                tagsCount: 0,
                tmplsCount: 0,
                currentTab: 'tmpl',//commodity、tmpl
                pageSize: 12,
                checkAll: false,
                target_size: ''
            }
        },
        computed: {
            tree_data() {
                return [this.$store.state.user_tree]
            },
            is_org() {
                return this.$store.state.default_bucket_tokens.org
            }
        },
        watch: {
            show(val) {
                this.modal = val
                if (val) this.$refs['tmplsComponent'].getData()
            }
        },
        methods: {
            handleVisibleChange(e) {
                if (!e) {
                    this.cancel()
                }
            },
            cancel() {
                this.tagsCount = 0
                this.commoditiesCount = 0
                this.tmplsCount = 0
                this.currentTab = 'tmpl'
                this.target_size = ''
                this.$refs['tagsComponent'].clear()
                this.$refs['tmplsComponent'].clear()
                this.$emit('cancel')
            },
            handleTagsCount(total) {
                this.tagsCount = total
            },
            handleTab(name) {
                this.currentTab = name
                if (name === 'tag' && this.$refs['tagsComponent'].total === 0) {
                    this.$refs['tagsComponent'].getData()
                } else if (name === 'tmpl' && this.$refs['tmplsComponent'].total === 0) {
                    this.$refs['tmplsComponent'].getData()
                }

            },
            handleTargetSize(target_size) {
                this.target_size = target_size
            },
            handleSubmit(done) {
                let params = {
                    "terminal_rowid_list": this.$refs['tagsComponent'].checkAllGroup,
                    "resource": {
                        "type": 'template',
                        "rowid": this.$refs['tmplsComponent'].tmpl,
                    },
                    "commodity_rowid_list": this.commodityList,
                    "plan": {
                        "date": {
                            "switch": true,
                            "start": "",
                            "end": ""
                        },
                        "week": {
                            "switch": true,
                            "weeks": [0, 1, 2]
                        },
                        "time": {
                            "switch": true,
                            "start": "",
                            "end": ""
                        }

                    }
                }
                this.$api.post('terminals/plans', params).then(() => {
                    this.cancel()
                }).catch(() => {}).finally(() => {
                    done()
                })
            }
        },
        created() {}
    }
</script>
<style scoped lang="less">
@import "~less/color";
.tabs {
    width: 100%;
    .tabPane {
        position: relative;
        display: inline-block;
        padding-bottom: 15px;
        width: 50%;
        border-radius: 10px 10px 0px 0px;
        font-size: 18px;
        line-height: 29px;
        text-align: center;
        color: #9a9a9a;
        font-weight: 400;
        &::after {
            position: absolute;
            content: '\200B';
            left: 0;
            bottom: 0;
            width: 100%;
            height: 3px;
            background: #ededed;
        }
    }
    .tabPaneActive {
        color: #000;
        &::after {
            background: @primary-color;
        }
    }
}
.tag-section,.commodity-section, .tmplsComponent {
    padding-top: 38px;
}
.tmplsComponent {
    position: relative;
}
</style>