<template>
    <div>
        <p class="tip">您选了{{ commodities }}个数据，已为您自动筛选匹配的模板</p>
        <div class="search-bar clearfix">
            <RadioGroup class="radio-group-button" v-model="bucket" type="button" @on-change="handleTmpl">
                <Radio v-if="is_org" label="org">企业空间</Radio>
                <Radio v-else label="user">个人空间</Radio>
                <Radio label="admin">更多AI模板</Radio>
            </RadioGroup>
            <Input v-model="query" class="float-right" clearable placeholder="输入模板名称搜索" style="width: auto" @keyup.native.enter="searchNew" @on-clear="searchNew" >
                <Icon type="ios-search" slot="suffix" @click="searchNew" />
            </Input>
        </div>
        <div class="radio-group-wrap">
            <RadioGroup
                class="tmpls-wrap"
                v-model="tmpl">
                <div
                    class="radio-wrap"
                    v-for="item in list"
                    :key="item.rowid">
                    <div class="img-wrap">
                        <img class="image-center" :src="`/cym/${item.cover_material_uri}/thumbnail?size=[-1,286]`">
                        <Radio class="radio" :label="item.rowid" @click.native="handleChange(item.target_size)"><span>&nbsp;</span></Radio>
                        <div class="marks">
                            <span
                                v-for="mark in item.mark_list"
                                :key="mark.key"
                                class="mark-item">{{ mark.text._ }}</span>
                        </div>
                    </div>
                    <div class="name-wrap">
                        <div class="name line-overflow">
                            {{ item.name }}
                        </div>
                    </div>
                </div>
            </RadioGroup>
            <Page
                v-show="list.length > 0"
                class="page"
                :total="total"
                :current="current"
                :page-size="pageSize"
                show-elevator
                @on-change="nextPage" />
            <noData v-show="list.length===0" text="暂无模板" />
            <Spin fix size="large" v-if="loading"></Spin>
        </div>
        <div class="modal-footer">
            <Button class="cancel margin-right" type="default" @click="cancel">取消</Button>
            <Button :disabled="tmpl==='' ? true : false "  type="primary"  @click="handleNext">下一步</Button>
        </div>
    </div>  
</template>
<script>
    import noData from 'views/components/noData'
    import { dropdownTree } from '@/mixins/index'

    export default {
        name: "",
        mixins: [dropdownTree],
        components: {
            noData
        },
        props: {
            commodities: {
                default: 0
            },
            is_org: {
                default: ''
            }
        },
        data () {
            return {
                total: 0,
                list: [],
                current: 1,
                pageSize: 15,
                query: '',
                loading: false,
                tmpl: '',
                bucket: ''
            }
        },
        computed: {
            tree_data() {
                return [this.$store.state.user_tree]
            },
        },
        methods: {
            handleTmpl() {
                this.getData()
            },
            searchNew() {
                if (this.loading) return
                this.current = 1
                this.getData()
            },
            nextPage(e) {
                this.current = e
                this.getData()
            },
            getData() {
                let params = {
                    query: this.query,
                    start: (this.current - 1) * this.pageSize,
                    count: this.pageSize,
                    data_type: 'dynamic',
                    data_num: this.commodities
                }
                switch (this.bucket) {
                    case 'user':
                        params.bucket_token = this.$store.state.default_bucket_tokens.user;
                        break;
                    case 'org':
                        this.treeList = [];
                        this.getTreeList(this.tree_data, 'bucket_token');
                        params.bucket_token_list = JSON.stringify(this.treeList);
                        break;
                    case 'admin':
                        params.bucket_token = 'admin';
                        break;
                }
                this.loading = true
                this.$api.get('templates/search', { params }).then(({ data, total_count, page })=>{
                    if (page === this.current) {
                        this.list = data
                        this.total = total_count
                        this.loading = false
                    }
                }).catch(() => {
                    this.loading = false
                })
            },
            clear() {
                this.list = 0
                this.current = 1
                this.query = ''
                this.total = 0
                this.tmpl = ''
            },
            handleNext() {
                this.$emit('handleTab', 'tag')
            },
            cancel() {
                this.$emit('cancel')
            },
            handleChange(target_size) {
                this.$emit('handleTargetSize', target_size)
            }
        },
        mounted() {
            if (this.is_org) {
                this.bucket = 'org'
            } else {
                this.bucket = 'user'
            }
        }
    }
</script>
<style scoped lang="less">
.tip {
    position: absolute;
    top: 0px;
    left: 0;
    line-height: 32px;
}
.search-bar {
    .radio-group-button {
        margin-left: -15px;
    }
}
.radio-group-wrap {
    overflow-x: hidden;
    position: relative;
    .tmpls-wrap {
        width: 1226px;
        .radio-wrap {
            position: relative;
            display: inline-block;
            margin: 0 56px 15px 0;
            padding-left: 0.7%;
            width: 189px;
            .img-wrap {
                position: relative;
                width: 100%;
                height: 153px;
                background: #f7f9fc;
                .radio {
                    position: absolute;
                    top: 0;
                    left: 0;
                    margin-right: 0;
                    padding: 5px;
                    width: 100%;
                    height: 100%;
                }
                .marks {
                    position: absolute;
                    left: 5px;
                    bottom: 10px;
                    font-size: 12px;
                    color: #fff;
                    .mark-item {
                        margin-right: 5px;
                        padding: 0 5px;
                        background: rgba(0,0,0,0.4);
                        border-radius: 4px;
                        line-height: 16px;
                    }
            }
            }
            .name-wrap {
                margin-top: 5px;
                .name {
                    font-size: 16px;
                    color: #6c6c6c;
                    text-align: center;
                }
            }
        }
    }
}
.modal-footer {
    text-align: right;
    .back {
        margin-right: 12px;
    }
}
</style>