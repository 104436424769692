<template>
    <Modal
        class="wrap"
        v-model="modal"
        title="数据面板管理"
        footer-hide
        :mask-closable="false"
        @on-visible-change="handleVisibleChange">
            <p>模板中的数据组件支持多面板，数据可选择不同的面板来展示不同的布局</p>
            <Button class="add" type="primary" @click="handleAddPanel">新增面板</Button>
            <Table border :columns="columns" :data="list" :loading="loading">
                <template slot-scope="{ row }" slot="text">
                    <span>{{ row.text._ }}</span>
                </template>
                <template slot-scope="{ row }" slot="action">
                    <span v-if="row.can_delete" class="margin-right pointer" @click="handleEdit(row)">重命名</span>
                    <span v-if="row.can_delete" class="pointer" @click="handleDelete(row.key)">删除</span>
                </template>
            </Table>
        <add-panel-modal
            :show="addPanelModalShow"
            :state="state"
            :bucket_token="bucket_token"
            :editInfo="editInfo"
            @confirm="handleAddPanelConfirm"
            @cancel="handlAddPanelCancel"></add-panel-modal>
    </Modal> 
</template>
<script>
    import addPanelModal from './addPanelModal'

    export default {
        name: "",
        mixins: [],
        components: {
            addPanelModal
        },
        props: {
            show: {
                default: false
            },
            bucket_token: {
                default: ''
            }
        },
        data () {
            return {
                modal: false,
                loading: false,
                addPanelModalShow: false,
                columns: [
                    {
                        title: '面板',
                        slot: 'text',
                        align: 'center'
                    },
                    {
                        title: '操作',
                        slot: 'action',
                        align: 'center'
                    }
                ],
                state: 'add',
                editInfo: {},
            }
        },
        computed: {
            list() {
                return this.$store.state.panels
            }
        },
        watch: {
            show(val) {
                this.modal =  val
                if (val) {
                    this.getList()
                }
            }
        },
        methods: {
            handleAddPanel() {
                this.addPanelModalShow = true
                this.state = 'add'
            },
            handleAddPanelConfirm() {
                this.getList()
                this.handlAddPanelCancel()
            },
            handlAddPanelCancel() {
                this.addPanelModalShow = false
            },
            handleEdit(row) {
                this.editInfo = row
                this.addPanelModalShow = true
                this.state = 'edit'
            },
            handleDelete(key) {
                this.$Modal.confirm({
                    title: '删除',
                    content: '确定删除吗?',
                    loading: true,
                    closable:  true,
                    onOk: () => {
                        this.$api.delete(`data_panels/${key}?bucket_token=${this.bucket_token}`).then(() => {
                            this.getList()
                        }).catch(() => {}).finally(() => {
                            this.$Modal.remove()
                        })
                    }
                })
            },
            handleVisibleChange(e) {
                if (!e) {
                    this.cancel()
                }
            },
            cancel() {
                this.$emit('cancel')
            },
            getList() {
                this.loading = true
                this.$store.dispatch('GET_PANELS').then(() => {
                }).catch(() => {}).finally(() => {
                    this.loading = false
                })
            }
        },
        created() {}
    }
</script>
<style scoped lang="less">
.add {
    margin: 10px 0;
}
</style>