<template>
    <Modal
        class="wrap"
        v-model="modal"
        title="设置标签"
        :width="387"
        :mask-closable="false"
        @on-visible-change="handleVisibleChange">
        <p>您已选{{ rowidList.length }}个商品，请选择想设置的商品标签</p>
        <Select
            class="select"
            v-model="labelIds"
            multiple
            filterable
            :remote-method="remoteMethod"
            :default-label="[]"
            :loading="labelLoading">
            <Option v-for="item in labelList" :value="item.rowid" :key="item.rowid">{{ item.name }}</Option>
        </Select>
        <div slot="footer">
            <Button type="default" @click="cancel">{{ $t('common.cancel') }}</Button>
            <Button type="primary" :loading="loading" @click="submit">{{ $t('common.confirm') }}</Button>
        </div>
    </Modal>
</template>
<script>
    export default {
        name: "",
        mixins: [],
        components: {},
        props: {
            show: {
                default: false
            },
            rowidList: {
                default: () => {
                    return []
                }
            },
            bucket_token: {
                default: ''
            }
        },
        data () {
            return {
                labelIds: [],
                labelList: [],
                labelLoading: false,
                modal: false,
                loading: false
            }
        },
        computed: {},
        watch: {
            show(val) {
                this.modal = val
                if (val) {
                    this.getLabelList()
                }
            }
        },
        methods: {
            handleVisibleChange(e) {
                if (!e) {
                    this.cancel()
                }
            },
            cancel() {
                this.labelIds = []
                this.labelList = []
                this.$emit('cancel')
            },
            submit() {
                if (!this.labelIds.length) return this.cancel()
                let params = {
                    bucket_token: this.bucket_token,
                    rowid_list: this.rowidList,
                    label_id_list: this.labelIds
                }
                this.loading = true
                this.$api.put('commodities/batch_labels', params).then(() => {
                    this.$emit('confirm')
                    this.cancel()
                }).catch(() => {}).finally(() => {
                    this.loading = false
                })
            },
            remoteMethod(query) {
                if (this.labelLoading) return
                if (query !== '') {
                    this.getLabelList(query)
                }
            },
            getLabelList(query='') {
                this.labelLoading  = true
                let params = {
                    query: query,
                    bucket_token: this.bucket_token,
                    label_type: 'commodity',
                    start: 0,
                    count: 10
                }
                this.$api.get('labels/list', { params }).then(({ data }) => {
                    this.labelList = data
                }).catch(() => {}).finally(() => {
                    this.labelLoading = false
                })
            },
        },
        created() {}
    }
</script>
<style scoped lang="less">
.select {
    margin-top: 30px;
    width: 80% !important;
}
</style>