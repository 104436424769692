<template>
    <Modal
        class="wrap"
        v-model="modal"
        :title="state==='add'?'新建数据':'编辑数据'"
        :width="1000"
        :mask-closable="false"
        @on-visible-change="handleVisibleChange"
        @paste.native="handleStop"
        @copy.native="handleStop"
        @cut.native="handleStop"
        @keydown.native="handleStop"
        @keyup.native="handleStop"
    >
        <div class="files-section">
            <div v-if="fields.banner_picture && fields.banner_picture.visible" class="file-wrap" >
                <div class="add-wrap pointer" @click="handleFile('exclusive_gallery_commodity')">
                    <Icon class="image-center" type="md-add" />
                </div>
                <div v-if="bannerPic.material_id" class="img-wrap">
                    <img class="image-center" :src="`${bannerPic.url}`">
                </div>
                <div v-if="fields.banner_picture" class="name line-overflow">{{ fields.banner_picture.text._ }}</div>
                <div v-if="bannerPic.material_id" class="icon-remove-wrap pointer" @click.stop="handleRemoveFile('exclusive_gallery_commodity')">
                    <i class="iconfont icon-lajitong icon-remove pointer"></i>
                </div>
                <div v-if="bannerPic.material_id" class="icon-replace-wrap pointer" @click.stop="handleFile('exclusive_gallery_commodity')">
                    <i class="iconfont-24px icon-tihuan1 pointer"></i>
                </div>
            </div>
            <!-- <div class="file-wrap pointer" >
                <div class="add-wrap pointer" @click="handleFile('')">
                    <Icon class="image-center" type="md-add" />
                </div>
                <div v-if="retouchedPic.material_id" class="img-wrap">
                    <img class="image-center" :src="`${retouchedPic.url}`">
                </div>
                <div v-if="fields.retouched_picture" class="name line-overflow">{{ fields.retouched_picture.text._ }}</div>
                <div v-if="retouchedPic.material_id" class="icon-remove-wrap pointer" @click.stop="handleRemoveFile('')">
                    <i class="iconfont icon-lajitong icon-remove pointer"></i>
                </div>
                <div v-if="retouchedPic.material_id" class="icon-replace-wrap pointer" @click.stop="handleFile('')">
                    <i class="iconfont-24px icon-tihuan1 pointer"></i>
                </div>
            </div> -->
            <div v-if="fields.banner_video &&  fields.banner_video.visible" class="file-wrap" >
                <div class="add-wrap pointer" @click="handleFile('exclusive_video_commodity')">
                    <Icon class="image-center" type="md-add" />
                </div>
                <div v-if="bannerVideo.material_id" class="img-wrap">
                    <img class="image-center" :src="`${bannerVideo.url}`">
                </div>
                <div v-if="fields.banner_video" class="name line-overflow">{{ fields.banner_video.text._ }}</div>
                <div v-if="bannerVideo.material_id" class="icon-remove-wrap pointer" @click.stop="handleRemoveFile('exclusive_video_commodity')">
                    <i class="iconfont icon-lajitong icon-remove pointer"></i>
                </div>
                <div v-if="bannerVideo.material_id" class="icon-replace-wrap pointer" @click.stop="handleFile('exclusive_video_commodity')">
                    <i class="iconfont-24px icon-tihuan1 pointer"></i>
                </div>
            </div>
            <div class="file-wrap" v-for="item in otherFiles" :key="item.key">
                <div class="add-wrap pointer" @click="handleFile(item.key)">
                    <Icon class="image-center" type="md-add" />
                </div>
                <div v-if="item.material_id" class="img-wrap">
                    <img class="image-center" :src="`${item.url}`">
                </div>
                <div class="name line-overflow">{{ item.text._ }}</div>
                <div v-if="item.material_id" class="icon-remove-wrap pointer" @click.stop="handleRemoveFile(item.key)">
                    <i class="iconfont icon-lajitong icon-remove pointer"></i>
                </div>
                <div v-if="item.material_id" class="icon-replace-wrap pointer" @click.stop="handleFile(item.key)">
                    <i class="iconfont-24px icon-tihuan1 pointer"></i>
                </div>
            </div>
        </div>
        <Form class="formItem" ref="formItem" :model="formItem" :rules="ruleValidate" :label-width="100">
            <template v-for="(item, key) in formItem">
                <FormItem :key="key" v-if="fields[key]&&fields[key].visible" :label="fields[key].text._" :prop="key">
                    <Input
                        v-if="fields[key].type==='price'"
                        type="text"
                        v-model="formItem[key]"
                        placeholder="请输入数字"
                        @on-change="handleNumberInput(key)"
                        @on-blur="handleNumberBlur(key)"></Input>
                    <Input
                        v-if="fields[key].type==='number'"
                        type="number"
                        v-model="formItem[key]"
                        placeholder="请输入数字"></Input>
                    <!-- <Select
                        v-if="fields[key].type==='tag'"
                        v-model="formItem[key]"
                        multiple
                        filterable
                        :remote-method="remoteMethod"
                        :default-label="[]"
                        :loading="labelLoading">
                        <Option v-for="item in labelList" :value="item.rowid" :key="item.rowid">{{ item.name }}</Option>
                    </Select> -->
                    <Select
                        v-if="fields[key].type==='boolean'"
                        v-model="formItem[key]">
                        <Option :value="1" :key="1">true</Option>
                        <Option :value="0" :key="0">false</Option>
                    </Select>
                    <Input
                        v-if="fields[key].type!=='price'&&fields[key].type!=='number'&&fields[key].type!=='tag'&&fields[key].type!=='boolean'&&key!=='data_panel'"
                        v-model="formItem[key]"
                        placeholder="请输入"
                        @paste.native="handleStop"
                        @copy.native="handleStop"
                        @cut.native="handleStop"
                        @keydown.native="handleStop"
                        @keyup.native="handleStop" ></Input>
                    <div v-show="fields[key].type==='qrcode'&&formItem[key]" class="qrcode">
                        <QrCode
                            :config="JSON.stringify({
                                text: formItem[key],
                                width: 50,
                                height: 50,
                                lightColor: '#ffffff',
                                darkColor: '#000000'
                            })"/>
                    </div>
                    <div v-show="fields[key].type==='barcode'&&formItem[key]" class="barcode">
                        <Barcode
                            :config="{
                                value: formItem[key],
                                width: 200,
                                height: 30,
                                lightColor: '#ffffff',
                                darkColor: '#000000'
                            }"/>
                    </div>
                    <Select
                            v-if="key==='data_panel'"
                            v-model="formItem['data_panel']">
                        <Option v-for="item in panels" :value="item.key" :key="item.key">{{ item.text._ }}</Option>
                    </Select>
                </FormItem>
            </template>
            <FormItem v-if="state==='add' && is_org" key="nodes" label="应用到门店" prop="nodes">
                <Select class="nodes" transfer multiple v-model="formItem.nodes">
                    <Option style="display: none;" v-for="item in nodeList" :value="item.bucket_token" :key="item.bucket_token">{{ item.title }}</Option>
                    <Tree :data="data" show-checkbox @on-check-change="handleTree2" @on-select-change="handleSelectChange2"></Tree>
                </Select>
            </FormItem>
        </Form>
        <div slot="footer">
            <Button type="default" @click="cancel">{{ $t('common.cancel') }}</Button>
            <Button type="primary" :loading="loading" @click="submit">{{ $t('common.confirm') }}</Button>
        </div>
        <file-modal
            :show="fileModalShow"
            :bucket_token="'m' + commodityRowid"
            :source="source"
            :name="formItem.name"
            :fields="fields"
            @cancel="hideFileModal"
            @confirm="confirmFileModal"
        ></file-modal>
    </Modal>
</template>
<script>
    import fileModal from './fileModal'
    import { QrCode, Barcode } from '@cym/base_lib'
    import { dropdownTree } from '@/mixins/index'

    export default {
        name: "",
        mixins: [dropdownTree],
        components: {
            fileModal,
            QrCode,
            Barcode
        },
        props: {
            show: {
                default: false
            },
            bucket_token: {
                default: ''
            },
            state: {
                default: 'add'
            },
            rowid: {
                default: null
            },
            is_org: {
                default: null
            }
        },
        data () {
            return {
                modal: false,
                loading: false,
                formItem: {},
                list: [],
                fields: {},
                bannerPic: {},
                bannerVideo: {},
                retouchedPic: {},
                fileModalShow: false,
                source: '',
                commodityRowid: null,
                ruleValidate: {
                    name: [
                        { required: true, message: '请输入', trigger: 'blur' }
                    ],
                    nodes: [
                        { required: true, type: 'array', message: '请选择', trigger: 'change' }
                    ],
                    data_panel: [
                        { required: true, message: '请选择', trigger: 'change' }
                    ],
                    qrcode: [
                        { required: false, message: '', validator: (rule, value, callback) => {
                            if (value.length > 512) return callback(new Error('不能超过512个字符'))
                            callback()
                        }, trigger: 'change' }
                    ]
                },
                labelList: [],
                labelLoading: false,
                otherFiles: [],
                nodeList: [],
                data: [],
            }
        },
        computed: {
            tree_data() {
                return [this.$store.state.user_tree]
            },
            panels() {
                return this.$store.state.panels
            }
        },
        watch: {
            async show(val) {
                this.modal = val
                if (val) {
                    this.commodityRowid = this.rowid
                    await this.getFields()
                    this.getKeys()
                }
            }
        },
        methods: {
            handleStop(e) {
                e.stopPropagation()
            },
            handleVisibleChange(e) {
                if (!e) {
                    this.cancel()
                }
            },
            cancel() {
                this.bannerPic = {}
                this.bannerVideo = {}
                this.retouchedPic = {}
                this.otherFiles = []
                this.commodityRowid = null
                this.ruleValidate = {
                    name: [
                        { required: true, message: '请输入', trigger: 'blur' }
                    ],
                    nodes: [
                        { required: true, type: 'array', message: '请选择', trigger: 'change' }
                    ],
                    data_panel: [
                        { required: true, message: '请选择', trigger: 'change' }
                    ],
                    qrcode: [
                        { required: false, message: '', validator: (rule, value, callback) => {
                            if (value.length > 512) return callback(new Error('不能超过512个字符'))
                            callback()
                        }, trigger: 'change' }
                    ]
                }
                this.labelList = []
                this.formItem = {}
                this.$refs.formItem.resetFields();
                this.$emit('cancel')
            },
            submit() {
                let result = this.panels.find((panel) => panel.key === this.formItem.data_panel)
                if (!result) this.formItem.data_panel = ''
                this.$refs.formItem.validate((valid) => {
                    if (valid) {
                        let obj = {
                            bucket_token: this.bucket_token,
                            rowid: this.commodityRowid
                        }
                        let params = JSON.parse(JSON.stringify(this.formItem))
                        for (let key in params) {
                            if (key === 'nodes' || key === 'data_panel' || key === 'tag') {
                                //处组织节点
                                continue
                            }
                            if (!this.fields[key]) continue
                            if (this.fields[key].type === 'price' || this.fields[key].type === 'number') {
                                if (params[key] === '') {
                                    params[key] = null
                                } else {
                                    params[key] = Number(params[key])
                                }
                            }
                            if (this.fields[key].type === 'boolean') {
                                params[key] = Boolean(params[key])
                            }
                        }
                        this.otherFiles.map((file) => {
                            if (file.material_id) {
                                params[file.key] = {
                                    material_id: file.material_id
                                }
                            } else {
                                params[file.key] = {}
                            }
                        })
                        params.tag = []
                        params.retouched_picture = {
                            material_id: this.retouchedPic.material_id
                        }
                        params.banner_picture = {
                            material_id: this.bannerPic.material_id
                        }
                        params.banner_video = {
                            material_id: this.bannerVideo.material_id
                        }
                        this.loading = true
                        // this.getLabelsByIds().then((data) => {
                            // let tag = []
                            // data.map((item) => {
                            //     let newItem = {
                            //         label_id: item.rowid,
                            //         name: item.name
                            //     }
                            //     tag.push(newItem)
                            // })
                            // params.tag = tag
                            if (this.state === 'add' && this.is_org) {

                                obj.bucket_token_list = params.nodes
                                delete params.nodes
                            }
                            obj.data = params
                            this.$api.put('commodities/single_commodity', obj).then(() => {
                                this.$emit('confirm')
                                this.cancel()
                            }).catch(() => {}).finally(() => {
                                this.loading = false
                            })
                        // }).catch(() => {
                        //     this.loading = false
                        // }).finally(() => {})
                    }
                })
            },
            getLabelsByIds() {
                let params = {
                    rowid_list: JSON.stringify(this.formItem.tag)
                }
                 return this.$api.get('labels/list_by_rowid_list', { params }).then(({ data }) => {
                    return data
                })
            },
            getFields() {
                let params = {
                    bucket_token: this.bucket_token
                }
                return this.$api.get('commodities/fields', { params }).then(({ data={} }) => {
                    this.fields = data
                    delete this.fields.tag
                    this.formItem = {
                        id: '',
                        name: '',
                        code: '',
                        price: null,
                        special_offer: null,
                        member_price: null,
                        intro: '',
                        note: '',
                        // tag: [],
                        unit: '',
                        model: '',
                        production: '',
                        web_site: ''
                    }
                })
            },
            getKeys() {
                let params = {
                    bucket_token: this.bucket_token,
                    rowid: this.rowid
                }
                this.$api.get('commodities/single_commodity', { params }).then(({ data={} }) => {
                    this.commodityRowid = data.rowid
                    delete data.data.tag
                    let  tags = []
                    for (let key in data.data) {
                        if (this.fields[key].type === 'price') {
                            let value = data.data[key]
                            if (value === null) {
                                value = ''
                            } else {
                                value = value.toFixed(2)
                            }
                            this.$set(this.formItem, key, value)
                        }
                        if (this.fields[key].type === 'number') {
                            let value = data.data[key]
                            if (value === null) {
                                value = ''
                            } else {
                                value = value.toString()
                            }
                            this.$set(this.formItem, key, data.data[key])
                        }
                        if (typeof data.data[key] === 'object') {
                            let params = {}
                            switch(key) {
                                case 'banner_picture':
                                    this.bannerPic = data.data[key]; this.bannerPic.url = '/cym/' + this.bannerPic.url; break;
                                case 'banner_video':
                                    this.bannerVideo = data.data[key]; this.bannerVideo.url = '/cym/' + this.bannerVideo.url + '/thumbnail';break;
                                case  'retouched_picture':
                                    this.retouchedPic = data.data[key]; this.retouchedPic.url = '/cym/' + this.retouchedPic.url + '/thumbnail';break;
                                case 'tag':
                                    this.$set(this.formItem, 'tag', tags)
                                    data.data[key].map((item) => {
                                        tags.push(item.label_id)
                                    });break;
                                default:
                                    params = {
                                        key: key,
                                        text: this.fields[key].text,
                                        material_id: data.data[key].material_id,
                                        url: `/cym/${data.data[key].url}/thumbnail`
                                    };
                                    this.otherFiles.push(params);
                                    break;
                            }
                        } else {
                            let type = typeof data.data[key]
                            if (['string', 'boolean'].indexOf(type) > -1) {
                                let value = data.data[key]
                                if (this.fields[key].type === 'boolean') {
                                    value = Number(value)
                                }
                                this.$set(this.formItem, key, value)
                            }
                        }
                    }
                    // this.getCurrentLabelList(tags).then(() => {
                    //     this.$set(this.formItem, 'tag', tags)
                    // });
                    if (this.state === 'add') {
                        this.$set(this.formItem, 'data_panel', 'default')
                        if (this.is_org) {
                            this.data  = this.handleRecursive(this.tree_data)
                            this.data[0].selected = true
                            this.nodeList = JSON.parse(JSON.stringify(this.data))
                            this.$set(this.formItem, 'nodes', [this.nodeList[0].bucket_token])
                        }
                    }
                })
            },
            handleNumberInput(key) {
                this.$nextTick(() => {
                    let value = this.formItem[key].replace(/[^\d.]/g, ''),
                        index = value.indexOf('.');
                    value = value.replace(/[.]/g, function (match, i) {
                        if (match === '.') {
                            if (i !== index) {
                                return ''
                            } else {
                                return match
                            }
                        }
                    })
                    this.$set(this.formItem, key, value)
                })
            },
            handleNumberBlur(key) {
                let value = this.formItem[key];
                if (value) {
                    if (value === '.') value = '0'
                    value = Number(value)
                    value = value.toFixed(2)
                }
                this.formItem[key] = value
            },
            handleFile(source) {
                this.$refs.formItem.validate((valid) => {
                    if (valid) {
                        this.source = source
                        this.fileModalShow = true
                    }
                })
            },
            handleRemoveFile(source) {
                let index = -1
                switch(source) {
                    case 'exclusive_gallery_commodity':
                        this.bannerPic = {}; break;
                    case 'exclusive_video_commodity':
                        this.bannerVideo = {}; break;
                    case 'exclusive_descriptions_commodity':
                        this.retouchedPic = {}; break;
                    default:
                        index = this.otherFiles.findIndex(file => file.key === source)
                        if (index > -1) {
                            this.otherFiles.splice(index, 1, { key: source, text: this.fields[source].text })
                        }
                        break;
                }
            },
            hideFileModal() {
                this.fileModalShow = false
            },
            confirmFileModal(params) {
                let item = {
                    material_id:  params.rowid,
                    name: params.name,
                    url: params.value
                }
                if (this.source === 'exclusive_gallery_commodity') {
                    this.bannerPic = item
                } else if (this.source === 'exclusive_video_commodity') {
                    this.bannerVideo = item
                } else if (this.source === 'exclusive_descriptions_commodity') {
                    this.retouchedPic = item
                } else {
                    let index = this.otherFiles.findIndex((file) => file.key === this.source)
                    Object.assign(this.otherFiles[index], item)
                }
            },
            getCurrentLabelList(tags) {
                if (!tags.length) return this.getLabelList()
                let params = {
                    rowid_list: JSON.stringify(tags)
                }
                return this.$api.get('labels/list_by_rowid_list', { params }).then(({ data }) => {
                    this.labelList = data
                    if (!data.length) {
                        this.getLabelList()
                    }
                })
            },
            getLabelList(query='') {
                this.labelLoading  = true
                let params = {
                    query: query,
                    bucket_token: this.bucket_token,
                    label_type: 'commodity',
                    start: 0,
                    count: 10
                }
                return this.$api.get('labels/list', { params }).then(({ data }) => {
                    this.labelList = data
                }).catch(() => {}).finally(() => {
                    this.labelLoading = false
                })
            },
            remoteMethod(query) {
                if (this.labelLoading) return
                if (query !== '') {
                    this.getLabelList(query)
                }
            },
            handleTree2(e) {
                this.handleTree(e)
                this.handleNodes()
            },
            handleSelectChange2(e) {
                this.handleSelectChange(e)
                this.handleNodes()
            },
            handleNodes() {
                this.formItem.nodes = []
                this.nodeList.map((node) => {
                    this.formItem.nodes.push(node.bucket_token)
                })
            }
        },
        mounted() {}
    }
</script>
<style scoped lang="less">
@deep: ~'>>>';
.input-number @{deep} .ivu-input-number-handler-wrap {
    display: none;
}
.wrap {
    user-select: none;
}
.files-section {
    .file-wrap {
        display: inline-block;
        vertical-align: top;
        position: relative;
        margin-right: 5px;
        width: 150px;
        height: 150px;
        border: 1.5px solid #808080;
        .add-wrap {
            position: absolute;
            top: 0;
            left: 0;
            width: 100%;
            height: 100%;
        }
        .icon-remove-wrap {
            position: absolute;
            top: 9px;
            right: 9px;
            width: 28px;
            height: 28px;
            border-radius: 50%;
            background: #999999;
            .icon-remove {
                position: absolute;
                top: 50%;
                left: 50%;
                transform: translate(-50%, -50%);
                color: #fff;
            }
        }
        .icon-replace-wrap {
            position: absolute;
            top: 9px;
            right: 46px;
            width: 28px;
            height: 28px;
            border-radius: 50%;
            background: #999999;
            .icon-tihuan1 {
                position: absolute;
                top: 50%;
                left: 50%;
                transform: translate(-50%, -50%);
                color: #fff;
            }
        }
        .img-wrap {
            position: relative;
            width: 100%;
            height: 100%;
        }
        .name {
            position: absolute;
            bottom: 0;
            left: 0;
            padding: 0 5px;
            width: 100%;
            height: 30px;
            background: #808080;
            font-size: 16px;
            line-height: 30px;
            color: #ececec;
            text-align: center;
        }
    }
}
.formItem {
    margin-top: 30px;
    width: 30%;
    @{deep} .ivu-icon-ios-close {
        display: none;
    }
    .qrcode {
        margin-top: 10px;
        height: 50px;
    }
    .barcode {
        margin-top: 10px;
        height: 30px;
    }
}
</style>